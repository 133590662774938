import React from 'react';
import * as Sentry from "@sentry/react";
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import './locales/i18n';
import 'semantic-ui-css/semantic.min.css'
import './index.scss';
import store from './redux/configureStore';
import App from './containers/App/App';
import 'semantic-ui-css/semantic.min.css'

import infos from '../package.json';

const renderApp = () => render(
    <Provider store={store}>
        <Router>
            <App />
        </Router>
    </Provider>,
    document.getElementById('root')
);

if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./containers/App/App', renderApp);
}

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: "https://af65e27348048a6b9331357b6eadb3d0@sentry.neto2.net/14",
        integrations: [
            Sentry.replayIntegration(),
        ],
        environment: process.env.NODE_ENV,
        release: infos.version,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: process.env.NODE_ENV === 'development' ? 1.0 : 0.25,

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        // Learn more at
        // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}

renderApp();

serviceWorker.register();
